import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import {
  Button,
  Card,
  Checkbox,
  DataTable,
  DialogContainer,
  EditDialogColumn,
  FontIcon,
  SelectField,
  SelectFieldColumn,
  TableBody,
  TableCardHeader,
  TableColumn,
  TableHeader,
  TableRow,
  TextField
} from 'react-md'
import { API_URL } from '../api-config'
import {
  addedReasons,
  droppedReasons,
  errorMessages,
  headers,
  sex
} from '../misc/collections'
import { nonNullString } from '../misc/utils'
import BaseComponent from './BaseComponent'
import PropTypes from 'prop-types'
import LoadingIndicator from './LoadingIndicator'

library.add(faUserMinus)

class Members extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      schoolLevels: [],
      statuses: [],
      church: {},
      members: [],
      addDialogVisible: false,
      dropDialogVisible: false,
      latestDropKey: -1,
      tableContents: [],
      hasMore: false,
      scrollToNewest: false,
      errors: [],
      toasts: [],
      ageHasError: false,
      phoneHasError: false,
      emailHasError: false
    }

    // Used for the Add Member Modal Dialog.
    this.controlledFields = {
      hasWaterBaptism: false,
      hasHolySpiritBaptism: false,
      hasSpecialNeeds: false,
      isMarried: false
    }
  }

  componentDidMount() {
    this.load()
  }

  load() {
    let that = this

    this.loadStaticData()
    this.loadChurchData().then(church => {
      this.setState({ church })

      that.loadMembers()
    })
  }

  componentDidUpdate() {
    this.scrollToNewest()
  }

  scrollToNewest() {
    if (this.state.scrollToNewest && !this.state.hasMore) {
      setTimeout(() => {
        window.requestAnimationFrame(() => {
          const element = document.querySelector(
            'tr[class=md-table-row]:nth-last-child(1) div[id^=addedReason-]'
          )

          if (element !== undefined) {
            element.scrollIntoView({ block: 'end', behavior: 'smooth' })
            this.state.scrollToNewest = false
          }
        })
      }, 200)
    }
  }

  loadStaticData() {
    this.executeStaticRequest('schoolLevels', 'schoolLevels')
    this.executeStaticRequest('statuses', 'statuses')
  }

  loadMembers() {
    this.setState({ hasMore: true })

    fetch(API_URL + 'members', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Year': this.getCurrentFiscalYear()
      },
      credentials: 'include'
    })
      .then(res => res.json())
      .then(json => {
        const members = json != null && json.members != null ? json.members : []

        this.state.members = members
        this.buildTableContents()
      })
  }

  verifyFieldHasError(fieldName, key, value) {
    let hasError = false

    if (!this.state.errors[fieldName]) {
      this.state.errors[fieldName] = []
    }

    if (fieldName === 'age') {
      hasError = !this.isAgeValid(parseInt(value))
    } else if (fieldName === 'email') {
      hasError = !this.isEmailValid(value)
    } else if (fieldName === 'phone') {
      hasError = !this.isPhoneValid(value)
    }

    if (this.state.errors[fieldName][key] !== hasError) {
      this.state.errors[fieldName][key] = hasError
      return true
    }

    return false
  }

  handleFieldChange(parent, value) {
    const [fieldName, key] = event.target.name.split('-')

    if (this.verifyFieldHasError(fieldName, key, value)) {
      this.state.tableContents[key] = this.buildTableRow(key)
      this.forceUpdate()
    }

    this.modifyMembersState(key, fieldName, value)
  }

  onCheckChange(parent, value, event) {
    const [fieldName, key] = event.target.name.split('-')

    parent.modifyMembersState(key, fieldName, event.target.checked)
  }

  onSelectChange(parent, value, pos, event, props) {
    const [fieldName, key] = props.name.split('-')

    parent.modifyMembersState(key, fieldName, props.value)
  }

  modifyMembersState(rowKey, fieldName, value) {
    this.state.members[rowKey][fieldName] = value
  }

  doSave(e, that, showAlert) {
    if (this.state.church.isSubmitted) {
      // Someone is trying to go thru this unauthorized.
      return
    }

    if (showAlert === undefined) {
      showAlert = true
    }

    fetch(API_URL + 'members', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Year': this.getCurrentFiscalYear()
      },
      credentials: 'include',
      body: JSON.stringify({ members: this.state.members })
    }).then(() => {
      if (showAlert) {
        this.addToast('Se guardaron los cambios realizados. ¡Gracias!', 'Ok')
      }
    })
  }

  addMember(parent) {
    if (this.state.church.isSubmitted) {
      // Someone is trying to go thru this unauthorized.
      return
    }

    const newMember = {
      name: parent.refs.name.getField().value,
      sex: parent.refs.sex.state.value,
      age: parent.refs.age.getField().value,
      phone: parent.refs.phone.getField().value,
      email: parent.refs.email.getField().value,
      schoolLevel: parent.refs.schoolLevel.state.value,
      schoolLevelDetails: '',
      status: parent.refs.status.state.value,
      hasWaterBaptism: parent.controlledFields.hasWaterBaptism,
      hasHolySpiritBaptism: parent.controlledFields.hasHolySpiritBaptism,
      hasSpecialNeeds: parent.controlledFields.hasSpecialNeeds,
      isMarried: parent.controlledFields.isMarried,
      addedReason: parent.refs.addedReason.state.value,
      droppedReason: ''
    }

    if (
      this.isBlank(newMember.name) ||
      this.isBlank(newMember.sex) ||
      this.isBlank(newMember.age) ||
      this.isBlank(newMember.addedReason)
    ) {
      this.addToast(
        'Los campos: Motivo de Alta, Nombre Completo, Sexo y Edad son requeridos para añadir un nuevo miembro a la sociedad local.',
        'Ok'
      )
      return
    }

    this.state.members.push(newMember)
    parent.setState({ addDialogVisible: false, scrollToNewest: true })

    this.doSave(null, null, false)
    this.buildTableContents()
  }

  onDialogCheckChange(parent, value, event) {
    parent.controlledFields[event.target.name] =
      event.target.checked === undefined ? false : event.target.checked
  }

  setDroppedReason(parent) {
    if (this.state.latestDropKey !== -1) {
      this.state.members[this.state.latestDropKey].addedReason = ''
      this.state.members[this.state.latestDropKey].droppedReason =
        parent.refs.droppedReason.state.value

      this.setState({ latestDropKey: -1, dropDialogVisible: false })
      this.doSave(null, null, false)
      this.buildTableContents()
    }
  }

  getActionsColumn(member, index) {
    if (nonNullString(member.droppedReason) !== '') {
      return <FontAwesomeIcon icon="user-minus" color="grey" />
    } else if (nonNullString(member.addedReason) !== '') {
      return <FontIcon style={{ color: 'green' }}>fiber_new</FontIcon>
    } else if (this.state.church.isSubmitted) {
      return null
    } else {
      return (
        <Button
          icon
          primary
          style={{ color: 'red' }}
          onClick={() =>
            this.setState({ latestDropKey: index, dropDialogVisible: true })}>
          remove_circle
        </Button>
      )
    }
  }

  getStatusColumn(member, index) {
    if (nonNullString(member.droppedReason) !== '') {
      return (
        <SelectFieldColumn
          id={'droppedReason-' + index}
          name={'droppedReason-' + index}
          placeholder="Razón de Baja"
          menuItems={droppedReasons}
          value={member.droppedReason}
          onChange={(...props) => this.onSelectChange(this, ...props)}
          disabled={this.state.church.isSubmitted}
        />
      )
    } else if (nonNullString(member.addedReason) !== '') {
      return (
        <SelectFieldColumn
          id={'addedReason-' + index}
          name={'addedReason-' + index}
          placeholder="Razón de Alta"
          menuItems={addedReasons}
          value={member.addedReason}
          onChange={(...props) => this.onSelectChange(this, ...props)}
          disabled={this.state.church.isSubmitted}
        />
      )
    } else {
      return <TableColumn style={{ textAlign: 'center' }}>Activo</TableColumn>
    }
  }

  buildTableRow(index) {
    const member = this.state.members[index]

    return (
      <TableRow key={index}>
        <TableColumn style={{ textAlign: 'left' }}>{index + 1}.</TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {this.getActionsColumn(member, index)}
        </TableColumn>
        {this.getStatusColumn(member, index)}

        <CustomEditDialogColumn
          label="Nombre Completo"
          fieldName="name"
          index={index}
          value={member.name}
          errors={this.state.errors}
          onFieldChange={(parent, value) => {
            this.handleFieldChange(parent, value)
          }}
          isReadOnly={
            this.state.church.isSubmitted ||
            nonNullString(member.addedReason) === ''
          }
        />
        <SelectFieldColumn
          menuItems={sex}
          defaultValue={nonNullString(member.sex)}
          name={'sex-' + index}
          onChange={(...props) => this.onSelectChange(this, ...props)}
          disabled={this.state.church.isSubmitted}
        />
        <CustomEditDialogColumn
          label="Edad"
          fieldName="age"
          index={index}
          value={member.age}
          errors={this.state.errors}
          onFieldChange={(parent, value) => {
            this.handleFieldChange(parent, value)
          }}
          isReadOnly={this.state.church.isSubmitted}
        />
        <CustomEditDialogColumn
          label="Teléfono"
          fieldName="phone"
          index={index}
          value={member.phone}
          errors={this.state.errors}
          onFieldChange={(parent, value) => {
            this.handleFieldChange(parent, value)
          }}
          isReadOnly={this.state.church.isSubmitted}
        />
        <CustomEditDialogColumn
          label="Correo Electrónico"
          fieldName="email"
          index={index}
          value={member.email}
          errors={this.state.errors}
          onFieldChange={(parent, value) => {
            this.handleFieldChange(parent, value)
          }}
          isReadOnly={this.state.church.isSubmitted}
        />
        <SelectFieldColumn
          menuItems={this.state.schoolLevels}
          defaultValue={nonNullString(member.schoolLevel)}
          name={'schoolLevel-' + index}
          onChange={(...props) => this.onSelectChange(this, ...props)}
          disabled={this.state.church.isSubmitted}
        />
        <SelectFieldColumn
          menuItems={this.state.statuses}
          defaultValue={nonNullString(member.status)}
          name={'status-' + index}
          onChange={(...props) => this.onSelectChange(this, ...props)}
          disabled={this.state.church.isSubmitted}
        />
        <TableColumn style={{ textAlign: 'center' }} adjusted={false}>
          <div style={{ width: 90 }}>
            <Checkbox
              id={'hasWaterBaptism-' + index}
              name={'hasWaterBaptism-' + index}
              aria-label="Bautismo en Agua"
              defaultChecked={member.hasWaterBaptism}
              onChange={(...props) => this.onCheckChange(this, ...props)}
              disabled={this.state.church.isSubmitted}
            />
          </div>
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }} adjusted={false}>
          <div style={{ width: 130 }}>
            <Checkbox
              id={'hasHolySpiritBaptism-' + index}
              name={'hasHolySpiritBaptism-' + index}
              aria-label="Bautismo en Espíritu Santo"
              defaultChecked={member.hasHolySpiritBaptism}
              onChange={(...props) => this.onCheckChange(this, ...props)}
              disabled={this.state.church.isSubmitted}
            />
          </div>
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }} adjusted={false}>
          <div style={{ width: 115 }}>
            <Checkbox
              id={'hasSpecialNeeds-' + index}
              name={'hasSpecialNeeds-' + index}
              aria-label="Diversidad Funcional"
              defaultChecked={member.hasSpecialNeeds}
              onChange={(...props) => this.onCheckChange(this, ...props)}
              disabled={this.state.church.isSubmitted}
            />
          </div>
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }} adjusted={false}>
          <div style={{ width: 80 }}>
            <Checkbox
              id={'isMarried-' + index}
              name={'isMarried-' + index}
              aria-label="Jóvenes Casados"
              defaultChecked={member.isMarried}
              onChange={(...props) => this.onCheckChange(this, ...props)}
              disabled={this.state.church.isSubmitted}
            />
          </div>
        </TableColumn>
      </TableRow>
    )
  }

  buildTableContents() {
    const tableContents = []

    for (let index = 0; index < this.state.members.length; index++) {
      let member = this.state.members[index]
      Object.keys(member).forEach(fieldName => {
        this.verifyFieldHasError(fieldName, index, member[fieldName])
      })

      tableContents.push(this.buildTableRow(index))
    }

    this.setTableContentsRecursively(tableContents)
  }

  setTableContentsRecursively(tableContents) {
    const REFRESH_MS = 15
    const step = 1

    setTimeout(() => {
      const size =
        this.state.tableContents !== undefined
          ? this.state.tableContents.length
          : 0
      const hasMore = size + step < tableContents.length

      this.setState(prev => ({
        tableContents: tableContents.slice(0, prev.tableContents.length + step),
        hasMore
      }))

      if (hasMore) {
        this.setTableContentsRecursively(tableContents)
      }
    }, REFRESH_MS)
  }

  render() {
    let schoolLevels = this.state.schoolLevels || []
    let statuses = this.state.statuses || []
    let allStaticLoaded = schoolLevels.length > 0 && statuses.length > 0

    const isMobile = window.innerWidth <= 900
    const dialogActions = [
      {
        secondary: true,
        swapTheming: true,
        flat: true,
        children: 'Cancelar',
        onClick: () => this.setState({ addDialogVisible: false }),
        iconEl: <FontIcon>close</FontIcon>
      },
      {
        children: 'Añadir',
        primary: true,
        swapTheming: true,
        flat: true,
        onClick: () => this.addMember(this),
        iconEl: <FontIcon>add</FontIcon>
      }
    ]

    const dropDialogActions = [
      {
        secondary: true,
        swapTheming: true,
        flat: true,
        children: 'Cancelar',
        onClick: () => this.setState({ dropDialogVisible: false }),
        iconEl: <FontIcon>close</FontIcon>
      },
      {
        children: 'Dar de Baja',
        primary: true,
        swapTheming: true,
        flat: true,
        onClick: () => this.setDroppedReason(this),
        iconEl: <FontIcon>remove</FontIcon>
      }
    ]

    const tableBody = () => {
      return this.state.tableContents
    }

    this.scrollToNewest()

    return (
      <Fragment>
        <Card tableCard className="md-block-centered">
          <TableCardHeader title="Membresía" visible={false}>
            {!this.state.church.isSubmitted && (
              <span style={{ marginRight: '10px' }}>
                <Button
                  primary
                  floating={isMobile}
                  mini={isMobile}
                  flat={!isMobile}
                  swapTheming={!isMobile}
                  iconEl={<FontIcon>add</FontIcon>}
                  onClick={() => {
                    this.state.ageHasError = false
                    this.state.phoneHasError = false
                    this.state.emailHasError = false
                    this.setState({ addDialogVisible: true })
                  }}
                  tooltipLabel="Añadir">
                  Añadir
                </Button>
                &nbsp;&nbsp;
                <Button
                  primary
                  floating={isMobile}
                  mini={isMobile}
                  flat={!isMobile}
                  swapTheming={!isMobile}
                  iconEl={<FontIcon>save</FontIcon>}
                  type="submit"
                  onClick={e => this.doSave(e, this)}
                  tooltipLabel="Guardar">
                  Guardar
                </Button>
              </span>
            )}
          </TableCardHeader>
          {this.state.hasMore && <LoadingIndicator />}
          {!allStaticLoaded && (
            <div className="md-body-2" style={{ padding: 10 }}>
              Hubo un problema cargando algunos datos importantes para mostrar
              la información requerida en esta tabla.
              <br /> Por favor, <u>refresque</u> ésta página a la brevedad
              posible.
              <br />
              <br />
              <Button
                primary
                flat
                swapTheming
                iconEl={<FontIcon>refresh</FontIcon>}
                onClick={() => this.load()}
                tooltipLabel="Refrescar Membresía">
                Refrescar Membresía
              </Button>
            </div>
          )}
          <DataTable
            baseId="members"
            selectableRows={false}
            fixedHeader
            fixedHeight={500}
            style={{ display: allStaticLoaded ? 'block' : 'none' }}>
            <TableHeader>
              <TableRow>
                {headers.map(({ name, ...props }, i) => (
                  <TableColumn {...props} key={i}>
                    {name}
                  </TableColumn>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>{tableBody()}</TableBody>
          </DataTable>
        </Card>
        <DialogContainer
          id="add-member-dialog"
          title="Añadir Miembro"
          visible={this.state.addDialogVisible}
          focusOnMount
          containFocus
          onHide={() => null}
          actions={dialogActions}
          contentClassName="md-grid"
          width={400}>
          <form
            onSubmit={e => e.preventDefault()}
            method="post"
            style={{ width: '100%' }}>
            <SelectField
              id="addedReason"
              name="addedReason"
              ref="addedReason"
              label="Motivo de Alta"
              menuItems={addedReasons}
              className="md-cell md-cell--12"
              required
            />
            <TextField
              id="name"
              name="name"
              ref="name"
              label="Nombre Completo"
              className="md-cell md-cell--12"
              required
            />
            <div className="md-grid">
              <SelectField
                id="sex"
                name="sex"
                ref="sex"
                label="Sexo"
                menuItems={sex}
                className="md-cell md-cell--6"
                required
              />
              <TextField
                id="age"
                name="age"
                ref="age"
                label="Edad"
                className="md-cell md-cell--6"
                error={this.state.ageHasError}
                errorText={errorMessages.age}
                onChange={value => {
                  this.setState({ ageHasError: !this.isAgeValid(value) })
                }}
                required
              />
            </div>
            <TextField
              id="phone"
              name="phone"
              ref="phone"
              label="Teléfono"
              className="md-cell md-cell--12"
              error={this.state.phoneHasError}
              errorText={errorMessages.phone}
              onChange={value => {
                this.setState({ phoneHasError: !this.isPhoneValid(value) })
              }}
            />
            <TextField
              id="email"
              name="email"
              ref="email"
              label="Correo Electrónico"
              className="md-cell md-cell--12"
              error={this.state.emailHasError}
              errorText={errorMessages.email}
              onChange={value => {
                this.setState({ emailHasError: !this.isEmailValid(value) })
              }}
            />
            <SelectField
              id="schoolLevel"
              name="schoolLevel"
              ref="schoolLevel"
              label="Estudios"
              menuItems={this.state.schoolLevels}
              className="md-cell md-cell--12"
            />
            <SelectField
              id="status"
              name="status"
              ref="status"
              label="Afiliación"
              menuItems={this.state.statuses}
              className="md-cell md-cell--6"
            />
            <Checkbox
              id="hasWaterBaptism"
              name="hasWaterBaptism"
              label="Bautismo en Agua"
              onChange={(...props) => this.onDialogCheckChange(this, ...props)}
            />
            <Checkbox
              id="hasHolySpiritBaptism"
              name="hasHolySpiritBaptism"
              label="Bautismo en Espíritu Santo"
              onChange={(...props) => this.onDialogCheckChange(this, ...props)}
            />
            <Checkbox
              id="hasSpecialNeeds"
              name="hasSpecialNeeds"
              label="Diversidad Funcional"
              onChange={(...props) => this.onDialogCheckChange(this, ...props)}
            />
            <Checkbox
              id="isMarried"
              name="isMarried"
              label="Jóvenes Casados"
              onChange={(...props) => this.onDialogCheckChange(this, ...props)}
            />
          </form>
        </DialogContainer>
        <DialogContainer
          id="drop-member-dialog"
          title="Dar de Baja a Miembro"
          visible={this.state.dropDialogVisible}
          focusOnMount
          containFocus
          onHide={() => null}
          actions={dropDialogActions}
          contentClassName="md-grid"
          width={400}>
          <SelectField
            id="droppedReason"
            name="droppedReason"
            ref="droppedReason"
            label="Razón de Baja"
            menuItems={droppedReasons}
            defaultValue={
              this.state.latestDropKey !== -1
                ? nonNullString(
                    this.state.members[this.state.latestDropKey].droppedReason
                  )
                : ''
            }
            className="md-cell md-cell--12"
            style={{ height: 500 }}
          />
        </DialogContainer>
        {this.getToastComponent()}
      </Fragment>
    )
  }
}

const CustomEditDialogColumn = props => {
  let name = `${props.fieldName}-${props.index}`
  let hasError =
    props.errors[props.fieldName] !== undefined
      ? props.errors[props.fieldName][props.index] || false
      : false
  let errorText = errorMessages[props.fieldName] || ''

  return !props.isReadOnly ? (
    <EditDialogColumn
      defaultValue={nonNullString(props.value)}
      label={props.label}
      name={name}
      onChange={(...params) => props.onFieldChange(this, ...params)}
      error={hasError}
      errorText={errorText}
    />
  ) : (
    <TableColumn>{nonNullString(props.value)}</TableColumn>
  )
}

CustomEditDialogColumn.defaultProps = {
  value: '',
  errors: [],
  onFieldChange: () => null,
  isReadOnly: false
}

CustomEditDialogColumn.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.any,
  errors: PropTypes.array,
  onFieldChange: PropTypes.func,
  isReadOnly: PropTypes.bool
}

export default Members
