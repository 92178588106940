import React from 'react'
import { CircularProgress } from 'react-md'

const LoadingIndicator = props => {
  return (
    <div className="md-grid">
      <CircularProgress
        id="tableProgress2"
        centered={false}
        className="md-cell md-cell--12"
      />
    </div>
  )
}

export default LoadingIndicator
