const headers = [
  { key: 'num', name: 'Núm.' },
  { key: 'actions', name: '' },
  { key: 'addStatus', name: 'Estatus' },
  { key: 'name', name: 'Nombre Completo', grow: true },
  { key: 'sex', name: 'Sexo' },
  { key: 'age', name: 'Edad' },
  { key: 'phone', name: 'Teléfono' },
  { key: 'email', name: 'Correo Electrónico' },
  { key: 'schoolLevel', name: 'Estudios' },
  { key: 'status', name: 'Afiliación' },
  { key: 'hasWaterBaptism', name: 'Bautismo en Agua' },
  { key: 'hasHolySpiritBaptism', name: 'Bautismo en Espíritu Santo' },
  { key: 'hasSpecialNeeds', name: 'Diversidad Funcional' },
  { key: 'isMarried', name: 'Jóvenes Casados' }
]

const sex = [
  { value: 'M', label: 'Masculino' },
  { value: 'F', label: 'Femenino' }
]

const addedReasons = [
  'Traslados de otro País',
  'Traslados de otra IDDPMI',
  'Traslados de otro Concilio',
  'Traslados de Niños a Juvenil',
  'Reconciliados',
  'Recién Convertido',
  'Corrección de Datos',
  'Otros'
]

const droppedReasons = [
  'Traslados a otro País',
  'Traslados a otra IDDPMI',
  'Traslados a otro Concilio',
  'Traslados a Damas y Caballeros',
  'Descarriados',
  'Corrección de Datos',
  'Otros'
]

const statsMap = [
  { name: 'JU', label: 'Juveniles' },
  { name: 'JO', label: 'Jóvenes' },
  { name: 'JA', label: 'Jóvenes Adultos' },
  { name: 'TOT', label: 'Totales' }
]

const errorMessages = {
  age: 'La edad tiene que ser un número entre 12 y 35 años.',
  email: 'El formato correcto de un correo electrónico es usuario@gmail.com.',
  phone: 'El formato correcto del número de teléfono es 787-123-4567.'
}

module.exports = {
  headers,
  sex,
  addedReasons,
  droppedReasons,
  statsMap,
  errorMessages
}
