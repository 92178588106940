import React from 'react'
import Helmet from 'react-helmet'
import { NavigationDrawer, Divider } from 'react-md'
import navItems from '../misc/navItems'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import {
  NavItemLink,
  Login,
  ChurchDemographics,
  Members,
  ChurchSummary,
  District,
  Region,
  Logout
} from './index'
import BaseComponent from './BaseComponent'

class App extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      navBarItems: [],
      key: ''
    }

    this.loadNavBarItems()
  }

  loadNavBarItems() {
    const navBarItems = navItems
      .filter(item => this.visibleRuleMatches(item.visibleRule))
      .map(item => {
        if (item.divider) {
          item.visibleRule = null

          return item
        }

        return this.getItem(item)
      })

    const activeItem = navBarItems.filter(item => item.active)
    let sourceItem = null

    if (activeItem.length === 0) {
      navBarItems[0].active = true
      sourceItem = navBarItems[0]
    } else {
      sourceItem = activeItem[0]
    }

    this.state = {
      navBarItems,
      key: sourceItem.key
    }
  }

  getItem(item) {
    return {
      key: item.key,
      label: item.label,
      to: '/' + item.key,
      icon: item.icon
    }
  }

  render() {
    let fiscalYear = this.getCurrentFiscalYear()
    let toolbarTitle = this.visibleRuleMatches('isLoggedIn')
      ? 'Afiliaciones - Año Fiscal ' + fiscalYear
      : 'Afiliaciones'
    return (
      <Router>
        <NavigationDrawer
          drawerTitle="AJEC PR"
          toolbarTitle={toolbarTitle}
          navItems={this.state.navBarItems.map(props =>
            props.divider ? (
              <Divider key={props.key} />
            ) : (
              <NavItemLink key={props.key} {...props} />
            )
          )}
          mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY_MINI}
          tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
          desktopDrawerType={NavigationDrawer.DrawerTypes.FULL_HEIGHT}
          contentClassName="md-grid">
          <Helmet title="AJEC PR - Afiliaciones" />

          <Switch>
            <Route exact path="/">
              {this.visibleRuleMatches('isNotLoggedIn') && (
                <Redirect to="/login" />
              )}
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/demographics">
              {this.visibleRuleMatches('isChurch') && <ChurchDemographics />}
            </Route>
            <Route path="/members">
              {this.visibleRuleMatches('isChurch') && <Members />}
            </Route>
            <Route path="/church-summary">
              {this.visibleRuleMatches('isChurch') && <ChurchSummary />}
            </Route>
            <Route path="/district">
              {this.visibleRuleMatches('isDistrict') && <District />}
            </Route>
            <Route path="/district/:id">
              {this.visibleRuleMatches('isRegion') && <District />}
            </Route>
            <Route path="/region">
              {this.visibleRuleMatches('isRegion') && <Region />}
            </Route>
            <Route path="/district/:id">
              {this.visibleRuleMatches('isRegion') && <District />}
            </Route>
            <Route path="/logout">
              <Logout />
            </Route>
          </Switch>
        </NavigationDrawer>
      </Router>
    )
  }
}

export default App
