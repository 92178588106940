import Cookies from 'js-cookie'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import React, { Component } from 'react'
import { Snackbar } from 'react-md'
import { API_URL } from '../api-config'

class BaseComponent extends Component {
  async executeStaticRequest(method, varName) {
    const response = await fetch(API_URL + method, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Year': Cookies.get('fiscalYear')
      },
      credentials: 'include'
    })
    const json = await response.json()

    this.state[varName] = json[varName]
    this.forceUpdate()
  }

  getJwtData() {
    const jwt = Cookies.get('jwt')
    let data = jwt !== undefined ? jwt_decode(jwt) : []

    return data
  }

  loadChurchData() {
    return new Promise((resolve, reject) => {
      fetch(API_URL + 'myChurch', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Year': this.getCurrentFiscalYear()
        },
        credentials: 'include'
      })
        .then(res => res.json())
        .then(json => resolve(json))
        .catch(err => reject(err))
    })
  }

  nonNullString(string) {
    return string == null || string === undefined ? '' : string
  }

  addToast(text, action) {
    const toasts = this.state.toasts.slice()
    toasts.push({
      text,
      action
    })
    this.setState({ toasts })
  }

  dismissToast() {
    const [, ...toasts] = this.state.toasts
    this.setState({ toasts })
  }

  getToastComponent() {
    return (
      <Snackbar
        id="interactive-snackbar"
        toasts={this.state.toasts}
        autohide
        autohideTimeout={parseInt(5000, 10)}
        autoFocusAction
        onDismiss={() => this.dismissToast()}
      />
    )
  }

  isAgeValid(age) {
    return age >= 12 && age <= 35
  }

  isEmailValid(email) {
    let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

    return re.test(email)
  }

  isPhoneValid(phone) {
    let re = /^([0-9]{3})-([0-9]{3})-([0-9]{4})$/

    return re.test(phone)
  }

  isBlank(str) {
    return !str || /^\s*$/.test(str)
  }

  getCurrentFiscalYear() {
    return Cookies.get('fiscalYear')
  }

  visibleRuleMatches(rule) {
    const jwt = Cookies.get('jwt')
    let data = jwt !== undefined ? jwt_decode(jwt) : []

    if (rule === 'isNotLoggedIn') {
      return jwt === undefined
    } else if (rule === 'isLoggedIn') {
      return jwt !== undefined
    } else if (rule === 'isChurch') {
      return jwt !== undefined && data.churchId && data.churchId !== ''
    } else if (rule === 'isDistrict') {
      return jwt !== undefined && data.district && data.district !== ''
    } else if (rule === 'isRegion') {
      return (
        jwt !== undefined &&
        data.region &&
        data.region !== '' &&
        data.region === true
      )
    }
  }
}

export default BaseComponent
