export const minReported = 7
export const cuamestralesQuote = 9
export const campQuote = 4
export const mizpaQuote = 3
export const getAffiliationQuote = fiscalYear => {
  if (parseInt(fiscalYear) === 2021) {
    return 1
  } else if (parseInt(fiscalYear) >= 2023) {
    return 4
  } else {
    return 3
  }
}
