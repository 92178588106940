import React, { createRef } from 'react'
import {
  Button,
  Card,
  CardText,
  CardTitle,
  TextField,
  SelectField
} from 'react-md'
import { API_URL } from '../api-config'
import BaseComponent from './BaseComponent'
import Cookies from 'js-cookie'

class Login extends BaseComponent {
  constructor(props) {
    super(props)

    this.username = createRef()
    this.password = createRef()
    this.fiscalYear = createRef()

    this.cardStyle = { maxWidth: 400 }
    this.textFieldStyle = { minWidth: 200, maxWidth: 600 }

    this.state = { error: '' }

    this.executeStaticRequest('fiscalYears', 'fiscalYears')
  }

  doLogin(e) {
    let fiscalYear = this.fiscalYear.current.state.activeLabel
    Cookies.set('fiscalYear', fiscalYear)

    fetch(API_URL + 'login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        username: this.username.current.value,
        password: this.password.current.value
      })
    }).then(res => {
      if (res.status === 400) {
        this.setState({
          error: 'Los campos de usuario y contraseña son requeridos.'
        })
      } else if (res.status === 401) {
        this.setState({ error: 'Su usuario y/o contraseña son incorrectos.' })
      } else if (res.status === 200) {
        window.location.assign('/demographics')
      }
    })
  }

  getCurrentFiscalYear() {
    let today = new Date()
    let curMonth = today.getMonth()

    return curMonth >= 6 ? today.getFullYear() + 1 : today.getFullYear()
  }

  render() {
    return (
      <Card style={this.cardStyle} className="md-block-centered">
        <CardTitle title="Conéctate" />
        <CardText>
          <form onSubmit={e => e.preventDefault()} method="post">
            <div
              style={{ color: 'red', fontSize: '12.5px' }}
              hidden={this.state.error === ''}>
              * {this.state.error}
            </div>
            <TextField
              id="username"
              name="username"
              label="Usuario"
              lineDirection="center"
              className="md-cell md-cell--bottom"
              style={this.textFieldStyle}
              required
              ref={this.username}
            />
            <TextField
              id="password"
              name="password"
              label="Contraseña"
              type="password"
              className="md-cell md-cell--bottom"
              style={this.textFieldStyle}
              required
              ref={this.password}
            />
            <SelectField
              id="fiscalYear"
              label="Año Fiscal"
              placeholder="Año Fiscal"
              className="md-cell md-cell--bottom"
              menuItems={this.state.fiscalYears}
              defaultValue={this.getCurrentFiscalYear()}
              simplifiedMenu
              required
              ref={this.fiscalYear}
            />
            <br />
            <Button
              flat
              primary
              swapTheming
              type="submit"
              onClick={e => this.doLogin(e)}>
              Conéctate
            </Button>
          </form>
        </CardText>
      </Card>
    )
  }
}

export default Login
