import React, { Component, Fragment } from 'react'
import { API_URL } from '../api-config'

class Logout extends Component {
  componentDidMount() {
    fetch(API_URL + 'logout', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }).then(res => {
      if (res.status === 200) {
        window.location.assign('/')
      }
    })
  }

  render() {
    return <Fragment>&nbsp;</Fragment>
  }
}

export default Logout
