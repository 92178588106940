import React, { Fragment } from 'react'
import {
  Card,
  CardText,
  CardTitle,
  DataTable,
  SelectionControl,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow
} from 'react-md'
import { API_URL } from '../api-config'
import {
  campQuote,
  cuamestralesQuote,
  minReported,
  mizpaQuote,
  getAffiliationQuote
} from '../misc/constants'
import BaseComponent from './BaseComponent'
import LoadingIndicator from './LoadingIndicator'

class District extends BaseComponent {
  constructor(props) {
    super(props)

    let data = this.getJwtData()
    this.state = {
      district: data.district,
      churches: [],
      totals: this.getEmptyStats(),
      onlyTotals: false,
      isLoading: true
    }

    this.loadDistrictData().then(json => {
      for (let j = 0; j < json.length; j++) {
        let members = json[j].members
        let stats = this.getEmptyStats()

        if (json[j].isSubmitted) {
          for (let i = 0; i < members.length; i++) {
            let member = members[i]
            let cat = null

            if (member.age > 1 && member.age <= 15) {
              cat = 'JU'
            } else if (member.age >= 16 && member.age <= 24) {
              cat = 'JO'
            } else if (member.age >= 25 && member.age <= 35) {
              cat = 'JA'
            }

            if (cat == null) {
              continue
            }

            if (member.droppedReason !== '' && member.droppedReason != null) {
              stats.DROPS_TOT += 1

              continue
            }

            stats[cat][member.sex]++
            stats[cat][member.status]++

            stats.TOT[member.sex]++
            stats.TOT[member.status]++

            if (member.hasWaterBaptism) {
              stats.TOT.BA++
              stats[cat].BA++
            }

            if (member.hasHolySpiritBaptism) {
              stats.TOT.BE++
              stats[cat].BE++
            }

            if (member.hasSpecialNeeds) {
              stats.TOT.NE++
              stats[cat].NE++
            }

            if (member.isMarried) {
              stats.TOT.JC++
              stats[cat].JC++
            }

            if (member.addedReason !== '' && member.addedReason != null) {
              stats.ADDS_TOT += 1
            }
          }
        }

        let quoteApply =
          stats.TOT.A > 0 && stats.TOT.A + stats.TOT.NA >= minReported

        if (quoteApply) {
          stats.CUAMESTRALES_QUOTE = cuamestralesQuote
          stats.CAMP_QUOTE = campQuote
          stats.MIZPA_QUOTE = mizpaQuote
        }

        stats.AFIL_QUOTE =
          stats.TOT.A * getAffiliationQuote(this.getCurrentFiscalYear())
        stats.QUOTES =
          stats.CUAMESTRALES_QUOTE +
          stats.CAMP_QUOTE +
          stats.MIZPA_QUOTE +
          stats.AFIL_QUOTE

        json[j].stats = stats

        let that = this
        let groups = ['JU', 'JO', 'JA', 'TOT']

        groups.forEach(group => {
          that.state.totals[group].A += stats[group].A
          that.state.totals[group].NA += stats[group].NA
          that.state.totals[group].M += stats[group].M
          that.state.totals[group].F += stats[group].F
          that.state.totals[group].BA += stats[group].BA
          that.state.totals[group].BE += stats[group].BE
          that.state.totals[group].NE += stats[group].NE
          that.state.totals[group].JC += stats[group].JC
        })

        that.state.totals.ADDS_TOT += stats.ADDS_TOT
        that.state.totals.DROPS_TOT += stats.DROPS_TOT
        that.state.totals.CUAMESTRALES_QUOTE += stats.CUAMESTRALES_QUOTE
        that.state.totals.CAMP_QUOTE += stats.CAMP_QUOTE
        that.state.totals.MIZPA_QUOTE += stats.MIZPA_QUOTE
        that.state.totals.AFIL_QUOTE += stats.AFIL_QUOTE
        that.state.totals.QUOTES += stats.QUOTES
      }

      this.setState({ churches: json, isLoading: false })
    })
  }

  loadDistrictData() {
    return new Promise((resolve, reject) => {
      fetch(API_URL + 'district', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Year': this.getCurrentFiscalYear()
        },
        credentials: 'include'
      })
        .then(res => res.json())
        .then(json => resolve(json))
        .catch(err => reject(err))
    })
  }

  getEmptyStats() {
    return {
      JU: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      JO: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      JA: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      TOT: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      ADDS: [],
      ADDS_TOT: 0,
      DROPS: [],
      DROPS_TOT: 0,
      CUAMESTRALES_QUOTE: 0,
      CAMP_QUOTE: 0,
      MIZPA_QUOTE: 0,
      AFIL_QUOTE: 0,
      QUOTES: 0
    }
  }

  churchGroupsToColumns(groups, church) {
    if (church.stats === undefined) {
      church = { stats: church }
    }

    return groups.map((group, index) => (
      <Fragment key={index}>
        <TableColumn style={{ textAlign: 'center' }}>
          {church.stats[group].A}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {church.stats[group].NA}
        </TableColumn>
        {group === 'TOT' && (
          <TableColumn style={{ textAlign: 'center' }}>
            {church.stats[group].A + church.stats[group].NA}
          </TableColumn>
        )}
        <TableColumn style={{ textAlign: 'center' }}>
          {church.stats[group].M}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {church.stats[group].F}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {church.stats[group].BA}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {church.stats[group].BE}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {church.stats[group].NE}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {church.stats[group].JC}
        </TableColumn>
      </Fragment>
    ))
  }

  render() {
    let district = this.state.district
    let groups = ['JU', 'JO', 'JA', 'TOT']

    return (
      <Card style={this.cardStyle} className="md-block-centered">
        <CardTitle
          title="Datos Demográficos Distritales"
          subtitle={`Distrito de ${district}`}
        />
        <CardText>
          {this.state.isLoading && <LoadingIndicator />}
          <SelectionControl
            id="only-totals"
            type="switch"
            label="Mostrar solo totales distritales."
            name="only-totals"
            onChange={onlyTotals => {
              this.setState({ onlyTotals })
            }}
          />
          <DataTable baseId="summary" selectableRows={false}>
            <TableHeader>
              <TableRow>
                <TableColumn>ID</TableColumn>
                <TableColumn>Iglesia</TableColumn>

                <TableColumn>T. Juv. Afiliados</TableColumn>
                <TableColumn>T. Juv. No Afiliados</TableColumn>
                <TableColumn>T. Juv. Másculinos</TableColumn>
                <TableColumn>T. Juv. Féminas</TableColumn>
                <TableColumn>T. Juv. B. en Agua</TableColumn>
                <TableColumn>T. Juv. B. en Espíritu Santo</TableColumn>
                <TableColumn>T. Juv. Diversidad Funcional</TableColumn>
                <TableColumn>T. Juv. Jóvenes Casados</TableColumn>

                <TableColumn>T. Jóv. Afiliados</TableColumn>
                <TableColumn>T. Jóv. No Afiliados</TableColumn>
                <TableColumn>T. Jóv. Másculinos</TableColumn>
                <TableColumn>T. Jóv. Féminas</TableColumn>
                <TableColumn>T. Jóv. B. en Agua</TableColumn>
                <TableColumn>T. Jóv. B. en Espíritu Santo</TableColumn>
                <TableColumn>T. Jóv. Diversidad Funcional</TableColumn>
                <TableColumn>T. Jóv. Jóvenes Casados</TableColumn>

                <TableColumn>T. Jóv. Ad. Afiliados</TableColumn>
                <TableColumn>T. Jóv. Ad. No Afiliados</TableColumn>
                <TableColumn>T. Jóv. Ad. Másculinos</TableColumn>
                <TableColumn>T. Jóv. Ad. Féminas</TableColumn>
                <TableColumn>T. Jóv. Ad. B. en Agua</TableColumn>
                <TableColumn>T. Jóv. Ad. B. en Espíritu Santo</TableColumn>
                <TableColumn>T. Jóv. Ad. Diversidad Funcional</TableColumn>
                <TableColumn>T. Jóv. Ad. Jóvenes Casados</TableColumn>

                <TableColumn>Total Afiliados</TableColumn>
                <TableColumn>Total No Afiliados</TableColumn>
                <TableColumn>Total Reportados</TableColumn>
                <TableColumn>Total Másculinos</TableColumn>
                <TableColumn>Total Féminas</TableColumn>
                <TableColumn>Total B. en Agua</TableColumn>
                <TableColumn>Total B. en Espíritu Santo</TableColumn>
                <TableColumn>Total Diversidad Funcional</TableColumn>
                <TableColumn>Total Jóvenes Casados</TableColumn>

                <TableColumn>C. Cuamestrales</TableColumn>
                <TableColumn>C. Campamento</TableColumn>
                <TableColumn>C. Mizpa</TableColumn>
                <TableColumn>C. Afiliaciones</TableColumn>
                <TableColumn>C. Total</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!this.state.onlyTotals &&
                this.state.churches.map((church, i) => (
                  <TableRow key={i}>
                    <TableColumn>
                      <b>{church.id}</b>
                    </TableColumn>
                    <TableColumn
                      style={{ color: church.isSubmitted ? 'green' : 'red' }}>
                      <b>{church.name}</b>
                    </TableColumn>
                    {this.churchGroupsToColumns(groups, church)}
                    <TableColumn style={{ textAlign: 'right' }}>
                      {church.stats.CUAMESTRALES_QUOTE}.00
                    </TableColumn>
                    <TableColumn style={{ textAlign: 'right' }}>
                      {church.stats.CAMP_QUOTE}.00
                    </TableColumn>
                    <TableColumn style={{ textAlign: 'right' }}>
                      {church.stats.MIZPA_QUOTE}.00
                    </TableColumn>
                    <TableColumn style={{ textAlign: 'right' }}>
                      {church.stats.AFIL_QUOTE}.00
                    </TableColumn>
                    <TableColumn style={{ textAlign: 'right' }}>
                      {church.stats.QUOTES}.00
                    </TableColumn>
                  </TableRow>
                ))}

              <TableRow>
                <TableColumn>&nbsp;</TableColumn>
                <TableColumn>
                  <b>TOTALES</b>
                </TableColumn>
                {this.churchGroupsToColumns(groups, this.state.totals)}
                <TableColumn style={{ textAlign: 'right' }}>
                  {this.state.totals.CUAMESTRALES_QUOTE}.00
                </TableColumn>
                <TableColumn style={{ textAlign: 'right' }}>
                  {this.state.totals.CAMP_QUOTE}.00
                </TableColumn>
                <TableColumn style={{ textAlign: 'right' }}>
                  {this.state.totals.MIZPA_QUOTE}.00
                </TableColumn>
                <TableColumn style={{ textAlign: 'right' }}>
                  {this.state.totals.AFIL_QUOTE}.00
                </TableColumn>
                <TableColumn style={{ textAlign: 'right' }}>
                  {this.state.totals.QUOTES}.00
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </CardText>
      </Card>
    )
  }
}

export default District
