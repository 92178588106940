import React, { Fragment } from 'react'
import {
  Card,
  CardText,
  CardTitle,
  DataTable,
  SelectionControl,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow
} from 'react-md'
import { API_URL } from '../api-config'
import { addedReasons, droppedReasons } from '../misc/collections'
import {
  campQuote,
  cuamestralesQuote,
  minReported,
  mizpaQuote,
  getAffiliationQuote
} from '../misc/constants'
import BaseComponent from './BaseComponent'
import LoadingIndicator from './LoadingIndicator'
import { Link } from 'react-router-dom'

class Region extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      districts: {},
      totals: this.getEmptyStats(),
      topReported: [],
      topAffiliations: [],
      onlyTotals: false,
      isLoading: true
    }

    this.loadRegionData().then(json => {
      let churches = json
      let districts = this.state.districts

      for (let j = 0; j < json.length; j++) {
        if (json[j].district === undefined) {
          continue
        }

        let members = json[j].members
        let stats =
          districts[json[j].district] === undefined
            ? this.getEmptyStats()
            : districts[json[j].district]

        stats.CHURCHES += 1

        let totA = 0
        let totNA = 0

        if (json[j].isSubmitted) {
          for (let i = 0; i < members.length; i++) {
            let member = members[i]
            let cat = null

            if (member.age > 1 && member.age <= 15) {
              cat = 'JU'
            } else if (member.age >= 16 && member.age <= 24) {
              cat = 'JO'
            } else if (member.age >= 25 && member.age <= 35) {
              cat = 'JA'
            }

            if (cat == null) {
              continue
            }

            if (member.droppedReason !== '' && member.droppedReason != null) {
              const reasonObj = stats.DROPS.find(
                obj => obj.desc === member.droppedReason
              )

              if (reasonObj === undefined) {
                stats.DROPS.push({ desc: member.droppedReason, qty: 1 })
              } else {
                reasonObj.qty += 1
              }

              stats.DROPS_TOT += 1

              continue
            }

            stats[cat][member.sex]++
            stats[cat][member.status]++

            stats.TOT[member.sex]++
            stats.TOT[member.status]++

            if (member.status === 'A') {
              totA += 1
            }

            if (member.status === 'NA') {
              totNA += 1
            }

            if (member.hasWaterBaptism) {
              stats.TOT.BA++
              stats[cat].BA++
            }

            if (member.hasHolySpiritBaptism) {
              stats.TOT.BE++
              stats[cat].BE++
            }

            if (member.hasSpecialNeeds) {
              stats.TOT.NE++
              stats[cat].NE++
            }

            if (member.isMarried) {
              stats.TOT.JC++
              stats[cat].JC++
            }

            if (member.addedReason !== '' && member.addedReason != null) {
              const reasonObj = stats.ADDS.find(
                obj => obj.desc === member.addedReason
              )

              if (reasonObj === undefined) {
                stats.ADDS.push({ desc: member.addedReason, qty: 1 })
              } else {
                reasonObj.qty += 1
              }

              stats.ADDS_TOT += 1
            }
          }
        }

        let moreThanMin = totA + totNA >= minReported
        let quoteApply = totA > 0 && moreThanMin

        if (quoteApply) {
          stats.CUAMESTRALES_QUOTE += cuamestralesQuote
          stats.CAMP_QUOTE += campQuote
          stats.MIZPA_QUOTE += mizpaQuote
        }

        if (moreThanMin && totA > 0) {
          stats.A_CHURCHES += 1
        } else if (!moreThanMin && totA > 0) {
          stats.D_OR_NEW_CHURCHES += 1
        } else if (json[j].isSubmitted) {
          stats.REP_CHURCHES += 1
        } else {
          stats.NO_SUB_CHURCHES += 1
        }

        stats.AFIL_QUOTE =
          stats.TOT.A * getAffiliationQuote(this.getCurrentFiscalYear())
        stats.QUOTES =
          stats.CUAMESTRALES_QUOTE +
          stats.CAMP_QUOTE +
          stats.MIZPA_QUOTE +
          stats.AFIL_QUOTE

        districts[json[j].district] = stats
      }

      let that = this
      let groups = ['JU', 'JO', 'JA', 'TOT']

      Object.keys(districts).forEach(name => {
        let stats = districts[name]

        groups.forEach(group => {
          that.state.totals[group].A += stats[group].A
          that.state.totals[group].NA += stats[group].NA
          that.state.totals[group].M += stats[group].M
          that.state.totals[group].F += stats[group].F
          that.state.totals[group].BA += stats[group].BA
          that.state.totals[group].BE += stats[group].BE
          that.state.totals[group].NE += stats[group].NE
          that.state.totals[group].JC += stats[group].JC
        })

        that.state.totals.ADDS_TOT += stats.ADDS_TOT
        that.state.totals.DROPS_TOT += stats.DROPS_TOT
        that.state.totals.CUAMESTRALES_QUOTE += stats.CUAMESTRALES_QUOTE
        that.state.totals.CAMP_QUOTE += stats.CAMP_QUOTE
        that.state.totals.MIZPA_QUOTE += stats.MIZPA_QUOTE
        that.state.totals.AFIL_QUOTE += stats.AFIL_QUOTE
        that.state.totals.QUOTES += stats.QUOTES

        that.state.totals.A_CHURCHES += stats.A_CHURCHES
        that.state.totals.D_OR_NEW_CHURCHES += stats.D_OR_NEW_CHURCHES
        that.state.totals.REP_CHURCHES += stats.REP_CHURCHES
        that.state.totals.NO_SUB_CHURCHES += stats.NO_SUB_CHURCHES
        that.state.totals.CHURCHES += stats.CHURCHES

        that.state.totals.ADDS.forEach((add, k) => {
          add.qty += stats.ADDS[k].qty
        })

        that.state.totals.DROPS.forEach((drop, k) => {
          drop.qty += stats.DROPS[k].qty
        })
      })

      churches.forEach(church => {
        if (church.REP === undefined) {
          church.REP = 0
        }

        if (church.A === undefined) {
          church.A = 0
        }

        church.members.forEach(member => {
          if (member.droppedReason !== '' && member.droppedReason != null) {
            return
          }

          if (member.status === 'A' || member.status === 'NA') {
            church.REP += 1
          }

          if (member.status === 'A') {
            church.A += 1
          }
        })
      })

      churches.sort((a, b) => parseInt(b.REP) - parseInt(a.REP))
      let topReported = churches.slice(0, 10)

      churches.sort((a, b) => parseInt(b.A) - parseInt(a.A))
      let topAffiliations = churches.slice(0, 10)

      this.setState({
        districts,
        topReported,
        topAffiliations,
        isLoading: false
      })
    })
  }

  loadRegionData() {
    return new Promise((resolve, reject) => {
      fetch(API_URL + 'region', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-Year': this.getCurrentFiscalYear()
        },
        credentials: 'include'
      })
        .then(res => res.json())
        .then(json => resolve(json))
        .catch(err => reject(err))
    })
  }

  getEmptyStats() {
    return {
      JU: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      JO: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      JA: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      TOT: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      ADDS: addedReasons.map(reason => ({ desc: reason, qty: 0 })),
      ADDS_TOT: 0,
      DROPS: droppedReasons.map(reason => ({ desc: reason, qty: 0 })),
      DROPS_TOT: 0,
      CUAMESTRALES_QUOTE: 0,
      CAMP_QUOTE: 0,
      MIZPA_QUOTE: 0,
      AFIL_QUOTE: 0,
      QUOTES: 0,
      A_CHURCHES: 0,
      D_OR_NEW_CHURCHES: 0,
      REP_CHURCHES: 0,
      NO_SUB_CHURCHES: 0,
      CHURCHES: 0
    }
  }

  churchGroupsToColumns(groups, district) {
    if (district.stats === undefined) {
      district = { stats: district }
    }

    return groups.map((group, index) => (
      <Fragment key={index}>
        <TableColumn style={{ textAlign: 'center' }}>
          {district.stats[group].A}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {district.stats[group].NA}
        </TableColumn>
        {group === 'TOT' && (
          <TableColumn style={{ textAlign: 'center' }}>
            {district.stats[group].A + district.stats[group].NA}
          </TableColumn>
        )}
        <TableColumn style={{ textAlign: 'center' }}>
          {district.stats[group].M}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {district.stats[group].F}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {district.stats[group].BA}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {district.stats[group].BE}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {district.stats[group].NE}
        </TableColumn>
        <TableColumn style={{ textAlign: 'center' }}>
          {district.stats[group].JC}
        </TableColumn>
      </Fragment>
    ))
  }

  reasonSummary(id, reasons, action, actionTotal) {
    return (
      <DataTable baseId={id} selectableRows={false}>
        <TableHeader>
          <TableRow>
            <TableColumn>Distrito</TableColumn>
            {reasons.map((reason, index) => (
              <TableColumn key={index}>{reason}</TableColumn>
            ))}
            <TableColumn>Total</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {!this.state.onlyTotals &&
            Object.keys(this.state.districts).map((name, index) => {
              let district = this.state.districts[name]

              return (
                <TableRow key={index}>
                  <TableColumn>
                    <b>{name}</b>
                  </TableColumn>
                  {district[action].map((item, index) => (
                    <TableColumn key={index}>{item.qty}</TableColumn>
                  ))}
                  <TableColumn>{district[actionTotal]}</TableColumn>
                </TableRow>
              )
            })}
          <TableRow>
            <TableColumn>
              <b>TOTALES</b>
            </TableColumn>
            {this.state.totals[action].map((item, index) => (
              <TableColumn key={index}>{item.qty}</TableColumn>
            ))}
            <TableColumn>{this.state.totals[actionTotal]}</TableColumn>
          </TableRow>
        </TableBody>
      </DataTable>
    )
  }

  churchesTop10(collectionName, metric) {
    return (
      <DataTable baseId={collectionName} selectableRows={false}>
        <TableHeader>
          <TableRow>
            <TableColumn>Distrito</TableColumn>
            <TableColumn>Iglesia</TableColumn>
            <TableColumn>Cantidad</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {this.state[collectionName].map((church, index) => (
            <TableRow key={index}>
              <TableColumn>{church.district}</TableColumn>
              <TableColumn>{church.name}</TableColumn>
              <TableColumn>{church[metric]}</TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </DataTable>
    )
  }

  render() {
    let groups = ['JU', 'JO', 'JA', 'TOT']

    return (
      <Card style={this.cardStyle} className="md-block-centered">
        <CardTitle
          title="Datos Demográficos Regionales"
          subtitle="Región de Puerto Rico"
        />
        <CardText>
          {this.state.isLoading && <LoadingIndicator />}
          <SelectionControl
            id="only-totals"
            type="switch"
            label="Mostrar solo totales regionales."
            name="only-totals"
            onChange={onlyTotals => {
              this.setState({ onlyTotals })
            }}
          />
          <DataTable baseId="summary" selectableRows={false}>
            <TableHeader>
              <TableRow>
                <TableColumn>Distrito</TableColumn>
                <TableColumn>Iglesias Afiliadas</TableColumn>
                <TableColumn>Afiliación Directa y Nuevas</TableColumn>
                <TableColumn>Iglesias Reportadas</TableColumn>
                <TableColumn>Iglesias no Reportadas</TableColumn>
                <TableColumn>Total de Iglesias</TableColumn>

                <TableColumn>T. Juv. Afiliados</TableColumn>
                <TableColumn>T. Juv. No Afiliados</TableColumn>
                <TableColumn>T. Juv. Másculinos</TableColumn>
                <TableColumn>T. Juv. Féminas</TableColumn>
                <TableColumn>T. Juv. B. en Agua</TableColumn>
                <TableColumn>T. Juv. B. en Espíritu Santo</TableColumn>
                <TableColumn>T. Juv. Diversidad Funcional</TableColumn>
                <TableColumn>T. Juv. Jóvenes Casados</TableColumn>

                <TableColumn>T. Jóv. Afiliados</TableColumn>
                <TableColumn>T. Jóv. No Afiliados</TableColumn>
                <TableColumn>T. Jóv. Másculinos</TableColumn>
                <TableColumn>T. Jóv. Féminas</TableColumn>
                <TableColumn>T. Jóv. B. en Agua</TableColumn>
                <TableColumn>T. Jóv. B. en Espíritu Santo</TableColumn>
                <TableColumn>T. Jóv. Diversidad Funcional</TableColumn>
                <TableColumn>T. Jóv. Jóvenes Casados</TableColumn>

                <TableColumn>T. Jóv. Ad. Afiliados</TableColumn>
                <TableColumn>T. Jóv. Ad. No Afiliados</TableColumn>
                <TableColumn>T. Jóv. Ad. Másculinos</TableColumn>
                <TableColumn>T. Jóv. Ad. Féminas</TableColumn>
                <TableColumn>T. Jóv. Ad. B. en Agua</TableColumn>
                <TableColumn>T. Jóv. Ad. B. en Espíritu Santo</TableColumn>
                <TableColumn>T. Jóv. Ad. Diversidad Funcional</TableColumn>
                <TableColumn>T. Jóv. Ad. Jóvenes Casados</TableColumn>

                <TableColumn>Total Afiliados</TableColumn>
                <TableColumn>Total No Afiliados</TableColumn>
                <TableColumn>Total Reportados</TableColumn>
                <TableColumn>Total Másculinos</TableColumn>
                <TableColumn>Total Féminas</TableColumn>
                <TableColumn>Total B. en Agua</TableColumn>
                <TableColumn>Total B. en Espíritu Santo</TableColumn>
                <TableColumn>Total Diversidad Funcional</TableColumn>
                <TableColumn>Total Jóvenes Casados</TableColumn>

                <TableColumn>C. Cuamestrales</TableColumn>
                <TableColumn>C. Campamento</TableColumn>
                <TableColumn>C. Mizpa</TableColumn>
                <TableColumn>C. Afiliaciones</TableColumn>
                <TableColumn>C. Total</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!this.state.onlyTotals &&
                Object.keys(this.state.districts).map((name, i) => {
                  let district = this.state.districts[name]

                  return (
                    <TableRow key={i}>
                      <TableColumn>
                        <Link to={`/district/${name}`}>
                          <b>{name}</b>
                        </Link>
                      </TableColumn>
                      <TableColumn>{district.A_CHURCHES}</TableColumn>
                      <TableColumn>{district.D_OR_NEW_CHURCHES}</TableColumn>
                      <TableColumn>{district.REP_CHURCHES}</TableColumn>
                      <TableColumn>{district.NO_SUB_CHURCHES}</TableColumn>
                      <TableColumn>{district.CHURCHES}</TableColumn>
                      {this.churchGroupsToColumns(groups, district)}
                      <TableColumn style={{ textAlign: 'right' }}>
                        {district.CUAMESTRALES_QUOTE}.00
                      </TableColumn>
                      <TableColumn style={{ textAlign: 'right' }}>
                        {district.CAMP_QUOTE}.00
                      </TableColumn>
                      <TableColumn style={{ textAlign: 'right' }}>
                        {district.MIZPA_QUOTE}.00
                      </TableColumn>
                      <TableColumn style={{ textAlign: 'right' }}>
                        {district.AFIL_QUOTE}.00
                      </TableColumn>
                      <TableColumn style={{ textAlign: 'right' }}>
                        {district.QUOTES}.00
                      </TableColumn>
                    </TableRow>
                  )
                })}

              <TableRow>
                <TableColumn>
                  <b>TOTALES</b>
                </TableColumn>
                <TableColumn>{this.state.totals.A_CHURCHES}</TableColumn>
                <TableColumn>{this.state.totals.D_OR_NEW_CHURCHES}</TableColumn>
                <TableColumn>{this.state.totals.REP_CHURCHES}</TableColumn>
                <TableColumn>{this.state.totals.NO_SUB_CHURCHES}</TableColumn>
                <TableColumn>{this.state.totals.CHURCHES}</TableColumn>
                {this.churchGroupsToColumns(groups, this.state.totals)}
                <TableColumn style={{ textAlign: 'right' }}>
                  {this.state.totals.CUAMESTRALES_QUOTE}.00
                </TableColumn>
                <TableColumn style={{ textAlign: 'right' }}>
                  {this.state.totals.CAMP_QUOTE}.00
                </TableColumn>
                <TableColumn style={{ textAlign: 'right' }}>
                  {this.state.totals.MIZPA_QUOTE}.00
                </TableColumn>
                <TableColumn style={{ textAlign: 'right' }}>
                  {this.state.totals.AFIL_QUOTE}.00
                </TableColumn>
                <TableColumn style={{ textAlign: 'right' }}>
                  {this.state.totals.QUOTES}.00
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
          <br />
          <h3>Resumen de Altas</h3>
          {this.reasonSummary('adds', addedReasons, 'ADDS', 'ADDS_TOT')}
          <br />
          <h3>Resumen de Bajas</h3>
          {this.reasonSummary('drops', droppedReasons, 'DROPS', 'DROPS_TOT')}
          <br />
          <h3>Sociedades con Mayor Número de Afiliados</h3>
          {this.churchesTop10('topAffiliations', 'A')}
          <br />
          <h3>Sociedades con Mayor Número de Reportados</h3>
          {this.churchesTop10('topReported', 'REP')}
        </CardText>
      </Card>
    )
  }
}

export default Region
