import React from 'react'
import {
  Login,
  ChurchDemographics,
  Members,
  ChurchSummary,
  District,
  Region
} from '../components/index'

const navItems = [
  {
    key: 'login',
    label: 'Conéctate',
    icon: 'perm_identity',
    content: <Login />,
    visibleRule: 'isNotLoggedIn'
  },
  {
    key: 'demographics',
    label: 'Demográficos de la Iglesia',
    icon: 'receipt',
    content: <ChurchDemographics />,
    visibleRule: 'isChurch'
  },
  {
    key: 'members',
    label: 'Membresía',
    icon: 'group',
    content: <Members />,
    visibleRule: 'isChurch'
  },
  {
    key: 'church-summary',
    label: 'Resumen',
    icon: 'bar_chart',
    content: <ChurchSummary />,
    visibleRule: 'isChurch'
  },
  {
    key: 'district',
    label: 'Resúmen del Distrito',
    icon: 'bar_chart',
    content: <District />,
    visibleRule: 'isDistrict'
  },
  {
    key: 'region',
    label: 'Resúmen Regional',
    icon: 'bar_chart',
    content: <Region />,
    visibleRule: 'isRegion'
  },
  {
    key: 'divider-1',
    divider: true,
    visibleRule: 'isLoggedIn'
  },
  {
    key: 'logout',
    label: 'Salir',
    icon: 'exit_to_app',
    visibleRule: 'isLoggedIn'
  }
]

export default navItems
