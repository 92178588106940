import React, { Fragment } from 'react'
import {
  Button,
  Card,
  CardText,
  CardTitle,
  FontIcon,
  SelectField,
  TextField
} from 'react-md'
import { API_URL } from '../api-config'
import BaseComponent from './BaseComponent'
import { withRouter } from 'react-router-dom'

class ChurchDemographics extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      towns: [],
      districts: [],
      church: {},
      toasts: []
    }
  }

  componentWillMount() {
    this.loadStaticData()
    this.loadChurchData().then(church => {
      this.setState({ church })
    })
  }

  loadStaticData() {
    this.executeStaticRequest('towns', 'towns')
    this.executeStaticRequest('districts', 'districts')
  }

  changeTextField(val, event) {
    const tempChurch = this.state.church

    tempChurch[event.target.id] = val

    this.setState({ church: tempChurch })
  }

  doSave(e, that) {
    if (this.state.church.isSubmitted) {
      // Someone is trying to go thru this unauthorized.
      return
    }

    fetch(API_URL + 'demographics', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Year': this.getCurrentFiscalYear()
      },
      credentials: 'include',
      body: JSON.stringify({ church: this.state.church })
    }).then(() => {
      this.addToast('Se guardaron los cambios realizados. ¡Gracias!', 'Ok')
    })
  }

  goToMembership(e, that) {
    this.props.history.push('/members')
  }

  render() {
    let canEditChurch = false

    let getTextField = (id, label, value) => {
      return (
        <TextField
          id={id}
          label={label}
          placeholder={label}
          lineDirection="center"
          className="md-cell"
          value={this.nonNullString(value)}
          onChange={(...props) => this.changeTextField(...props)}
          disabled={this.state.church.isSubmitted}
        />
      )
    }

    return (
      <Fragment>
        <Card style={{ maxWidth: 640 }} className="md-block-centered">
          <CardTitle
            title="Demográficos de la Iglesia"
            subtitle={
              'ID Iglesia: ' +
              this.state.church.id +
              ' | Año Fiscal: ' +
              this.state.church.fiscalYear
            }
          />
          <CardText>
            <SelectField
              id="district"
              ref="district"
              label="Distrito"
              placeholder="Distrito"
              className="md-cell"
              value={this.state.church.district}
              menuItems={this.state.districts}
              onChange={val => {
                this.state.church.district = val
              }}
              disabled={!canEditChurch}
            />
            <SelectField
              id="town"
              label="Pueblo"
              placeholder="Pueblo"
              className="md-cell"
              value={this.state.church.town}
              menuItems={this.state.towns}
              onChange={val => {
                this.state.church.town = val
              }}
              disabled={!canEditChurch}
            />
            {getTextField('name', 'Nombre', this.state.church.name)}
            <br />
            {getTextField(
              'ministerFullName',
              'Nombre Completo del Pastor(a)',
              this.state.church.ministerFullName
            )}
            {getTextField(
              'ministerPhone',
              'Teléfono del Pastor(a)',
              this.state.church.ministerPhone
            )}
            {getTextField(
              'ministerEmail',
              'Correo Electrónico del Pastor(a)',
              this.state.church.ministerEmail
            )}
            <br />
            {getTextField(
              'presidentFullName',
              'Nombre Completo del Presidente(a)',
              this.state.church.presidentFullName
            )}
            {getTextField(
              'presidentPhone',
              'Teléfono del Presidente(a)',
              this.state.church.presidentPhone
            )}
            {getTextField(
              'presidentEmail',
              'Correo Electrónico del Presidente(a)',
              this.state.church.presidentEmail
            )}
            <br />
            {!this.state.church.isSubmitted && (
              <Button
                flat
                primary
                swapTheming
                iconEl={<FontIcon>save</FontIcon>}
                type="submit"
                onClick={e => this.doSave(e, this)}>
                Guardar
              </Button>
            )}
            &nbsp;&nbsp;
            <Button
              flat
              secondary
              swapTheming
              iconEl={<FontIcon>group</FontIcon>}
              type="button"
              onClick={e => this.goToMembership(e, this)}>
              Ir a Membresía
            </Button>
          </CardText>
        </Card>
        {this.getToastComponent()}
      </Fragment>
    )
  }
}

export default withRouter(ChurchDemographics)
