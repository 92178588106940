import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAt,
  faExclamationTriangle,
  faPhone,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import {
  Avatar,
  Button,
  Card,
  CardText,
  CardTitle,
  Chip,
  DataTable,
  FontIcon,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow
} from 'react-md'
import {
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis
} from 'react-vis'
import { API_URL } from '../api-config'
import { statsMap } from '../misc/collections'
import {
  campQuote,
  cuamestralesQuote,
  minReported,
  mizpaQuote,
  getAffiliationQuote
} from '../misc/constants'
import BaseComponent from './BaseComponent'

library.add(faExclamationTriangle)
library.add(faPhone)
library.add(faAt)
library.add(faUser)

const dateFormat = require('dateformat')

const isDisplayAddsDropPlotEnabled = false

// https://www.npmjs.com/package/dateformat
const formatOfDate = 'yyyy-mm-dd h:MM:ss TT'

class ChurchSummary extends BaseComponent {
  constructor(props) {
    super(props)

    this.state = {
      church: {},
      stats: this.getEmptyStats(),
      members: [],
      errors: [],
      toasts: []
    }
  }

  componentDidMount() {
    this.loadChurchData().then(json => this.processChurchData(json))
  }

  getEmptyStats() {
    return {
      JU: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      JO: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      JA: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      TOT: {
        A: 0,
        NA: 0,
        M: 0,
        F: 0,
        BA: 0,
        BE: 0,
        NE: 0,
        JC: 0
      },
      ADDS: [],
      ADDS_TOT: 0,
      DROPS: [],
      DROPS_TOT: 0
    }
  }

  processChurchData(json) {
    let errors = []
    let members = json.members
    const stats = this.getEmptyStats()

    for (let i = 0; i < members.length; i++) {
      let cat = null
      let error = {}
      const member = members[i]

      if (
        this.isBlank(member.name) ||
        this.isBlank(member.sex) ||
        this.isBlank(member.age)
      ) {
        error.required = true
      }

      if (!this.isAgeValid(member.age)) {
        error.age = true
      }

      if (!this.isBlank(member.phone) && !this.isPhoneValid(member.phone)) {
        error.phone = true
      }

      if (!this.isBlank(member.email) && !this.isEmailValid(member.email)) {
        error.email = true
      }

      if (member.age > 1 && member.age <= 15) {
        cat = 'JU'
      } else if (member.age >= 16 && member.age <= 24) {
        cat = 'JO'
      } else if (member.age >= 25 && member.age <= 35) {
        cat = 'JA'
      }

      if (member.droppedReason !== '' && member.droppedReason != null) {
        const reasonObj = stats.DROPS.find(
          obj => obj.desc === member.droppedReason
        )

        if (reasonObj === undefined) {
          stats.DROPS.push({ desc: member.droppedReason, qty: 1 })
        } else {
          reasonObj.qty += 1
        }

        stats.DROPS_TOT += 1

        errors.push(false)
        continue
      }

      errors.push(Object.keys(error).length === 0 ? false : error)

      if (cat == null) {
        continue
      }

      stats[cat][member.sex]++
      stats[cat][member.status]++

      stats.TOT[member.sex]++
      stats.TOT[member.status]++

      if (member.hasWaterBaptism) {
        stats.TOT.BA++
        stats[cat].BA++
      }

      if (member.hasHolySpiritBaptism) {
        stats.TOT.BE++
        stats[cat].BE++
      }

      if (member.hasSpecialNeeds) {
        stats.TOT.NE++
        stats[cat].NE++
      }

      if (member.isMarried) {
        stats.TOT.JC++
        stats[cat].JC++
      }

      if (member.addedReason !== '' && member.addedReason != null) {
        const reasonObj = stats.ADDS.find(
          obj => obj.desc === member.addedReason
        )

        if (reasonObj === undefined) {
          stats.ADDS.push({ desc: member.addedReason, qty: 1 })
        } else {
          reasonObj.qty += 1
        }

        stats.ADDS_TOT += 1
      }
    }

    stats.ADDS.sort((a, b) => (a.qty > b.qty ? -1 : 1))
    stats.DROPS.sort((a, b) => (a.qty > b.qty ? -1 : 1))

    this.setState({ church: json, stats, members, errors })
  }

  doesQuotesApply() {
    // Affiliation process 2019-2021 doesn't include the other quotes.
    if (this.state.church.fiscalYear === 2021) {
      return false
    }

    return (
      this.state.stats.TOT.A > 0 &&
      this.state.stats.TOT.A + this.state.stats.TOT.NA >= minReported
    )
  }

  calculateTotal() {
    return (
      (this.doesQuotesApply()
        ? cuamestralesQuote + campQuote + mizpaQuote
        : 0) +
      this.state.stats.TOT.A * getAffiliationQuote(this.state.church.fiscalYear)
    )
  }

  doSubmit(e, that) {
    const church = this.state.church

    church.isSubmitted = true
    church.submittedDate = Date.now()

    fetch(API_URL + 'demographics', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Year': this.getCurrentFiscalYear()
      },
      credentials: 'include',
      body: JSON.stringify({ church })
    }).then(() => {
      this.setState({ church })
      this.addToast(
        `Se sometió la afiliación. Ya no se podrán hacer mas cambios por el año fiscal ${church.fiscalYear}. ¡Gracias!`,
        'Ok'
      )
    })
  }

  displayAddsDropPlot() {
    if (!isDisplayAddsDropPlotEnabled) {
      return null
    }

    return (
      <XYPlot
        xType="ordinal"
        width={
          (this.state.stats.DROPS.length + this.state.stats.ADDS.length) * 185
        }
        height={300}
        xDistance={50}
        stackBy="y">
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        {this.state.stats.DROPS.map(reason => (
          <VerticalBarSeries
            key={reason.desc}
            data={[{ x: reason.desc, y: reason.qty * -1 }]}
          />
        ))}
        {this.state.stats.ADDS.map(reason => (
          <VerticalBarSeries
            key={reason.desc}
            data={[{ x: reason.desc, y: reason.qty }]}
          />
        ))}
      </XYPlot>
    )
  }

  render() {
    let errors = this.state.errors || []
    let hasErrors = errors.filter(error => error).length > 0
    const stats = statsMap.map((stat, i) => {
      return {
        ...stat,
        ...this.state.stats[stat.name]
      }
    })

    let getChip = (label, icon) => (
      <span>
        <Chip
          label={label}
          avatar={<Avatar icon={<FontAwesomeIcon icon={icon} size="xs" />} />}
        />
        &nbsp;
      </span>
    )

    const reasonSummary = (baseId, headerText, arr, total) => (
      <DataTable baseId={baseId} selectableRows={false} fullWidth>
        <TableHeader>
          <TableRow>
            <TableColumn>Descripción</TableColumn>
            <TableColumn style={{ textAlign: 'center', width: '20%' }}>
              {headerText}
            </TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {arr.map((reason, i) => (
            <TableRow key={i}>
              <TableColumn>{reason.desc}</TableColumn>
              <TableColumn style={{ textAlign: 'center' }}>
                {reason.qty}
              </TableColumn>
            </TableRow>
          ))}
          <TableRow key="TOT">
            <TableColumn>
              <b>Total</b>
            </TableColumn>
            <TableColumn style={{ textAlign: 'center' }}>
              <b>{total}</b>
            </TableColumn>
          </TableRow>
        </TableBody>
      </DataTable>
    )

    return (
      <Fragment>
        <Card style={{ maxWidth: '95%' }} className="md-block-centered">
          <CardTitle
            title="Resumen"
            subtitle={
              'ID Iglesia: ' +
              this.state.church.id +
              ' | Año Fiscal: ' +
              this.state.church.fiscalYear
            }
          />
          <CardText>
            {hasErrors && (
              <span>
                <h3 style={{ marginBottom: 0 }}>Errores</h3>
                <i>
                  Su afiliación no puede ser sometida hasta que se atiendan los
                  siguientes errores:{' '}
                </i>
                {errors.map(
                  (error, i) =>
                    error && (
                      <div style={{ padding: 5, margin: 5 }}>
                        <div style={{ marginBottom: 3 }}>
                          <b>Miembro #{i + 1}</b> | <b>Nombre</b>:{' '}
                          {this.state.members[i].name}
                        </div>
                        {error.required &&
                          getChip(
                            'Faltan campos requeridos',
                            'exclamation-triangle'
                          )}
                        {error.age && getChip('Edad inválida', 'user')}
                        {error.phone && getChip('Teléfono inválido', 'phone')}
                        {error.email &&
                          getChip('Correo Electrónico inválido', 'at')}
                      </div>
                    )
                )}
              </span>
            )}
            <h3>Resumen Estadístico</h3>
            <DataTable baseId="summary" selectableRows={false} fullWidth>
              <TableHeader>
                <TableRow>
                  <TableColumn>Categoría</TableColumn>
                  <TableColumn>Afiliados</TableColumn>
                  <TableColumn>No Afiliados</TableColumn>
                  <TableColumn>B. en Agua</TableColumn>
                  <TableColumn>B. en Espíritu Santo</TableColumn>
                  <TableColumn>Diversidad Funcional</TableColumn>
                  <TableColumn>Jóvenes Casados</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {stats.map((stat, i) => (
                  <TableRow key={i}>
                    <TableColumn>
                      <b>{stat.label}</b>
                    </TableColumn>
                    <TableColumn style={{ textAlign: 'center' }}>
                      {stat.A}
                    </TableColumn>
                    <TableColumn style={{ textAlign: 'center' }}>
                      {stat.NA}
                    </TableColumn>
                    <TableColumn style={{ textAlign: 'center' }}>
                      {stat.BA}
                    </TableColumn>
                    <TableColumn style={{ textAlign: 'center' }}>
                      {stat.BE}
                    </TableColumn>
                    <TableColumn style={{ textAlign: 'center' }}>
                      {stat.NE}
                    </TableColumn>
                    <TableColumn style={{ textAlign: 'center' }}>
                      {stat.JC}
                    </TableColumn>
                  </TableRow>
                ))}
              </TableBody>
            </DataTable>
            <br />
            <h3>Resumen de Altas y Bajas</h3>
            <div className="md-grid">
              <div className="md-cell md-cell--6">
                {reasonSummary(
                  'summaryDrop',
                  'Bajas',
                  this.state.stats.DROPS,
                  this.state.stats.DROPS_TOT
                )}
              </div>
              <div className="md-cell md-cell--6">
                {reasonSummary(
                  'summaryAdd',
                  'Altas',
                  this.state.stats.ADDS,
                  this.state.stats.ADDS_TOT
                )}
              </div>
            </div>
            {this.displayAddsDropPlot()}
            <br />
            <h3>Desglose de Cuotas</h3>
            <DataTable baseId="dues" selectableRows={false} fullWidth={false}>
              <TableHeader>
                <TableRow>
                  <TableColumn>Cuota</TableColumn>
                  <TableColumn>Cantidad</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow key={0}>
                  <TableColumn>
                    Afiliación ($
                    {getAffiliationQuote(this.state.church.fiscalYear)} por
                    Afiliado)
                  </TableColumn>
                  <TableColumn style={{ textAlign: 'right' }}>
                    {this.state.stats.TOT.A *
                      getAffiliationQuote(this.state.church.fiscalYear)}
                    .00
                  </TableColumn>
                </TableRow>
                <TableRow key={1}>
                  <TableColumn>Cuamestrales</TableColumn>
                  <TableColumn style={{ textAlign: 'right' }}>
                    {this.doesQuotesApply() ? cuamestralesQuote : 0}.00
                  </TableColumn>
                </TableRow>
                <TableRow key={2}>
                  <TableColumn>Campamento</TableColumn>
                  <TableColumn style={{ textAlign: 'right' }}>
                    {this.doesQuotesApply() ? campQuote : 0}.00
                  </TableColumn>
                </TableRow>
                <TableRow key={3}>
                  <TableColumn>Fondo para Becas MIZPA</TableColumn>
                  <TableColumn style={{ textAlign: 'right' }}>
                    {this.doesQuotesApply() ? mizpaQuote : 0}.00
                  </TableColumn>
                </TableRow>
                <TableRow key={4}>
                  <TableColumn>
                    <b>Total</b>
                  </TableColumn>
                  <TableColumn style={{ textAlign: 'right' }}>
                    ${this.calculateTotal()}.00
                  </TableColumn>
                </TableRow>
              </TableBody>
            </DataTable>
            {!this.state.church.isSubmitted && !hasErrors ? (
              <Button
                flat
                primary
                swapTheming
                iconEl={<FontIcon>done</FontIcon>}
                type="submit"
                onClick={e => this.doSubmit(e, this)}>
                Someter Afiliación
              </Button>
            ) : hasErrors ? null : (
              <span>
                <br />
                <br />
                <i>
                  Su afiliación ya fue sometida el{' '}
                  {dateFormat(this.state.church.submittedDate, formatOfDate)}.
                </i>
                <br />
                <br />
                <Button
                  flat
                  primary
                  swapTheming
                  iconEl={<FontIcon>receipt</FontIcon>}
                  type="button"
                  href={
                    API_URL +
                    `certification?year=${
                      this.state.church.fiscalYear
                    }&ministerFullName=${this.state.church.ministerFullName ||
                      ''}&churchName=${this.state.church.name ||
                      ''}&churchDistrict=${this.state.church.district ||
                      ''}&affiliates=${this.state.stats.TOT.A}&noAffiliates=${
                      this.state.stats.TOT.NA
                    }&total=${this.state.stats.TOT.A +
                      this.state.stats.TOT
                        .NA}&quotes=${this.calculateTotal()}.00`
                  }>
                  Ver Certificación Pastoral
                </Button>
              </span>
            )}
          </CardText>
        </Card>
        {this.getToastComponent()}
      </Fragment>
    )
  }
}

export default ChurchSummary
